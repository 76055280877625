import React, { createContext, useState, useEffect } from "react";
type loginForm = {
  pin: string;
  nombre: string;
  correo: string;
  telefono: string;
  terminos: boolean;
};

type authContext = {
  isAuth: boolean;
  login: Function;
  loginState: {
    data: any;
    loading: boolean;
    error: any;
  };
  checkSession: Function;
  codigo: { value: string; set: any };
  loginForm: { value: loginForm; set: any };
};
const Context = createContext<authContext | any>({});

const Provider = ({ children }: any) => {
  const [isAuth, setIsAuth] = useState<any>(undefined);
  const [codigo, setCodigo] = useState<string>("");

  const [values, setValues] = useState<loginForm>({
    correo: "",
    pin: "",
    nombre: "",
    telefono: "",
    terminos: false
  });

  const [loginState, setLoginState] = useState({
    data: null,
    loading: false,
    error: null
  });

  useEffect(() => {
    const checkSession = async () => {
      console.log("check session");
      try {
        setLoginState({ ...loginState, loading: true });
        var formdata = new FormData();
        if (codigo) formdata.append("codigo", codigo);
        else throw Error("URL inválida");

        await fetch(
          "https://www.live.orglaesperanza.com/php/checksession.php",
          {
            method: "POST",
            body: formdata,
            credentials: "include",
            mode: "cors"
          }
        )
          .then((res: any) => res.json())
          .then((res: any) => {
            if (res.codigo >= 400) throw res.mensaje;
            else {
              setLoginState({ ...loginState, loading: false });
              setValues({ ...values, ...res });
              setIsAuth(true);
              console.log("session encontrada");
              return res;
            }
          })
          .catch((err: any) => {
            throw err;
          });
      } catch (error) {
        setLoginState({ ...loginState, loading: false });
        setIsAuth(false);
      }
    };
    if (codigo) checkSession();
  }, [codigo]);

  const login = async (formValues: any) => {
    try {
      setLoginState({ ...loginState, loading: true });

      var formdata = new FormData();
      for (const prop in formValues) {
        formdata.append(prop, formValues[prop]);
      }
      formdata.append("codigo", codigo);

      await fetch("https://www.live.orglaesperanza.com/php/login.php", {
        method: "POST",
        body: formdata,
        credentials: "include",
        mode: "cors"
      })
        .then((res: any) => res.json())
        .then((res: any) => {
          if (res.codigo !== 200) throw res.mensaje;
          setLoginState({ ...loginState, data: res.mensaje, loading: false });
          setIsAuth(true);

          return res;
        })
        .catch((err: any) => {
          throw err;
        });
    } catch (error) {
      setLoginState({ ...loginState, error: error, loading: false });
      setValues({ ...values, pin: "" });
    }
  };

  return (
    <Context.Provider
      value={{
        isAuth,
        login,
        loginState,
        codigo: {
          value: codigo,
          set: setCodigo
        },
        loginForm: {
          value: values,
          set: setValues
        }
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default {
  Provider,
  Context
};
