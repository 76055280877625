import {
  IonModal,
  IonText,
  IonCard,
  IonItem,
  IonIcon,
  IonInput,
  IonLabel,
  IonCheckbox,
  IonFooter,
  IonButton,
  IonSpinner,
  IonContent,
  IonGrid,
  IonRow
} from "@ionic/react";
import React, {
  useContext,
  useEffect,
  Fragment,
  useState,
  useRef
} from "react";
import { call, lock, person, at, alert } from "ionicons/icons";
import AuthContext from "../context/authContext";
type loginForm = {
  pin: string;
  nombre: string;
  correo: string;
  telefono: string;
  terminos: boolean;
};

type authContext = {
  isAuth: boolean;
  login: Function;
  loginState: {
    data: any;
    loading: boolean;
    error: any;
  };
  checkSession: Function;
  codigo: { value: string; set: any };
  loginForm: { value: loginForm; set: any };
};

const LoginModal: React.FC<any> = () => {
  const { isAuth, login, loginForm, loginState } = useContext<authContext>(
    AuthContext.Context
  );
  const [showModal, setShowModal] = useState(false);
  const modal: any = useRef(null);
  useEffect(() => {
    console.log("login modal sesion", isAuth);
    if (isAuth) setShowModal(false);
    else setShowModal(true);
  }, [isAuth]);

  return (
    <IonModal isOpen={showModal} ref={modal}>
      {loginState.loading ? (
        <IonContent>
          <IonGrid style={{ height: "100%" }}>
            <IonRow
              className="ion-justify-content-center
              ion-align-items-center"
              style={{ height: "100%" }}
            >
              <IonText className="ion-text-center" style={{ opacity: "0.5" }}>
                <p>
                  <img
                    src="./assets/logo.svg"
                    style={{ height: "5em" }}
                    alt="Organización La Esperanza"
                  />
                  <br />
                  <br />
                  <IonSpinner name="crescent"></IonSpinner>
                </p>
              </IonText>
            </IonRow>
          </IonGrid>
        </IonContent>
      ) : (
        <Fragment>
          <IonContent>
            <div
              style={{
                overflow: "auto"
              }}
            >
              <IonText className="ion-text-center">
                <p>
                  <img
                    src="./assets/logo.svg"
                    style={{ height: "5em" }}
                    alt="Organización La Esperanza"
                  />
                </p>
              </IonText>
              <IonCard color="light">
                <IonItem lines="none">
                  <IonIcon color="medium" slot="start" icon={lock}></IonIcon>

                  <IonInput
                    value={loginForm.value.pin}
                    onIonChange={(e: any) => {
                      loginForm.set({
                        ...loginForm.value,
                        pin: e.detail.value
                      });
                    }}
                    type="number"
                    placeholder="Pin de conexión"
                  />
                </IonItem>
              </IonCard>
              <hr />
              <IonCard color="light">
                <IonItem lines="none">
                  <IonIcon
                    color="medium"
                    slot="start"
                    mode="md"
                    icon={person}
                  ></IonIcon>
                  <IonInput
                    onIonChange={(e: any) => {
                      loginForm.set({
                        ...loginForm.value,
                        nombre: e.detail.value
                      });
                    }}
                    type="text"
                    placeholder="Nombre completo"
                  />
                </IonItem>
              </IonCard>
              <IonCard color="light">
                <IonItem lines="none">
                  <IonIcon color="medium" slot="start" icon={at}></IonIcon>
                  <IonInput
                    onIonChange={(e: any) => {
                      loginForm.set({
                        ...loginForm.value,
                        correo: e.detail.value
                      });
                    }}
                    type="email"
                    placeholder="Correo electrónico"
                  />
                </IonItem>
              </IonCard>
              <IonCard color="light">
                <IonItem lines="none">
                  <IonIcon color="medium" slot="start" icon={call}></IonIcon>
                  <IonInput
                    onIonChange={(e: any) => {
                      loginForm.set({
                        ...loginForm.value,
                        telefono: e.detail.value
                      });
                    }}
                    type="tel"
                    placeholder="Teléfono"
                  />
                </IonItem>
              </IonCard>
              <hr />
              <IonCard color="light">
                <IonItem lines="none">
                  <IonLabel>Acepto los términos y condiciones</IonLabel>
                  <IonCheckbox
                    onIonChange={(e: any) => {
                      loginForm.set({
                        ...loginForm.value,
                        terminos: e.detail.checked
                      });
                    }}
                    color="primary"
                  />
                </IonItem>
              </IonCard>
              <IonCard className="ion-no-margin ion-no-padding">
                <IonText className="ion-no-margin  ion-text-center">
                  <p className="ion-no-margin ion-no-padding">
                    <a
                      href="http://www.organizacionlaesperanza.com/terminos_condiciones.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Términos y condiciones
                    </a>
                  </p>
                </IonText>
              </IonCard>
            </div>
          </IonContent>

          <IonFooter className="ion-padding">
            {loginState.error ? (
              <IonText color="danger">
                <p>
                  <IonIcon icon={alert}></IonIcon> {loginState.error + ""}
                </p>
              </IonText>
            ) : null}
            {
              <IonButton
                disabled={
                  !(
                    loginForm.value.pin &&
                    loginForm.value.nombre &&
                    loginForm.value.correo &&
                    loginForm.value.telefono &&
                    loginForm.value.terminos
                  )
                }
                expand="block"
                onClick={() => {
                  login(loginForm.value);
                }}
              >
                {loginState.loading ? "Cargando..." : "Iniciar sesión"}
              </IonButton>
            }
          </IonFooter>
        </Fragment>
      )}
    </IonModal>
  );
};

export default LoginModal;
