import {
  IonContent,
  IonPage,
  IonFab,
  IonFabButton,
  IonMenu,
  IonMenuToggle,
  IonIcon,
  IonSpinner,
  IonLoading
} from "@ionic/react";

import React, { useState, useEffect, useContext } from "react";
import LoginModal from "../modals/loginModal";
import ObituarioMenu from "../modals/obituarioMenu";
import AuthContext from "../context/authContext";
//@ts-ignore
import VideoPlayer from "react-video-js-player";
import ReactPlayer from "react-player";
import "videojs-logo";

import io from "socket.io-client";

import { person, volumeHigh, volumeMute, volumeOff } from "ionicons/icons";
import useDeviceOrientation from "../hooks/deviceOrientation";
import useIsMobileBrowser from "../hooks/mobileBrowser";

type loginForm = {
  pin: string;
  nombre: string;
  correo: string;
  telefono: string;
  terminos: boolean;
};

type authContext = {
  isAuth: boolean;
  login: Function;
  loginState: {
    data: any;
    loading: boolean;
    error: any;
  };
  checkSession: Function;
  codigo: { value: string; set: any };
  loginForm: { value: loginForm; set: any };
};

const Home: React.FC<{ match: { params: any } }> = ({ match }) => {
  // const [showModal, setShowModal] = useState(true);

  const [isLive, setLive] = useState(false);
  const [isMuted, setMuted] = useState(true);
  const deviceOrientation = useDeviceOrientation();
  const isMobileBrowser = useIsMobileBrowser();

  const { isAuth, codigo, loginForm } = useContext<authContext>(
    AuthContext.Context
  );
  var timer: any = null;
  useEffect(() => {
    codigo.set(match.params.codigo);
  }, [codigo, match.params.codigo]);

  useEffect(() => {
    console.log("Inicio sesion", isAuth);
  }, [isAuth]);

  useEffect(() => {
    if (!isLive && isAuth) {
      console.log("consultando video");
      if (loginForm.value.pin && codigo.value) {
        let i = 0;
        timer = setInterval(async () => {
          i = i + 1;
          await fetch(
            `http://rtmp.orglaesperanza.com:8200/live/` +
              codigo.value +
              "-" +
              loginForm.value.pin +
              `/index.m3u8`
          ).then((res: any) => {
            if (res.ok) {
              clearInterval(timer);
              setLive(true);
            } else {
              setLive(false);
            }
          });
        }, 1500);
      }
    }
  }, [isAuth, isLive, loginForm.value.pin, codigo.value]);

  return (
    <IonPage>
      {isAuth && (
        <IonMenu
          type="overlay"
          side="end"
          contentId="test"
          class="my-custom-menu"
        >
          <ObituarioMenu
            menuMode={true}
            id={atob(match.params.codigo)}
          ></ObituarioMenu>
        </IonMenu>
      )}

      {isAuth === false && <LoginModal></LoginModal>}

      <IonContent id="test">
        {deviceOrientation === "landscape" && !isMobileBrowser && (
          <IonFab horizontal="end" vertical="top">
            <IonMenuToggle menu="end">
              <IonFabButton>
                <IonIcon icon={person} mode="md"></IonIcon>
              </IonFabButton>
            </IonMenuToggle>
          </IonFab>
        )}

        {isAuth && codigo.value && loginForm.value.pin && !isLive && (
          // <IonFab horizontal="center" vertical="center">
          //   <IonSpinner color="light" name="crescent" />
          // </IonFab>
          <IonLoading isOpen={!isLive} message={"Conectando"} />
        )}

        <IonFab horizontal="start" vertical="bottom">
          <IonFabButton
            translucent={true}
            mode="ios"
            color="light"
            onClick={() => setMuted(!isMuted)}
          >
            <IonIcon
              icon={isMuted ? volumeHigh : volumeOff}
              mode="md"
            ></IonIcon>
          </IonFabButton>
        </IonFab>

        <IonFab horizontal="end" vertical="bottom">
          <IonMenuToggle menu="end">
            <img
              style={{ width: "10vw", opacity: "0.6" }}
              src="./logo-tinta.svg"
              alt=""
            />
          </IonMenuToggle>
        </IonFab>
        <div
          style={{
            height: deviceOrientation === "portrait" ? "56.25vw" : "100%",
            background: "black",
            width: "100%",
            overflow: "hidden"
          }}
        >
          {isAuth && codigo.value && loginForm.value.pin && isLive && (
            <ReactPlayer
              playing={isLive}
              muted={isMuted}
              url={
                `http://rtmp.orglaesperanza.com:8200/live/` +
                codigo.value +
                "-" +
                loginForm.value.pin +
                `/index.m3u8`
              }
              width="100%"
              height="100%"
              onError={e => {
                console.log("error", e);
                setLive(false);
              }}
            />
            // <VideoPlayer
            //   poster={
            //     "https://www.organizacionlaesperanza.com/imagenes/vdo_cabezotes/cabe_f0b829d6fe81f8acaf433bbc325626ef.jpg"
            //   }
            //   controls={true}
            //   src={
            //     `http://rtmp.orglaesperanza.com:8200/live/` +
            //     codigo.value +
            //     "-" +
            //     loginForm.value.pin +
            //     `/index.m3u8`
            //   }
            //   bigPlayButton={true}
            //   bigPlayButtonCentered={true}
            //   autoplay={true}
            //   onReady={(player: any) => {
            //     console.log("Inicio videoJS", player);
            //     // setIsLive(true);
            //   }}
            //   onEnd={(player: any) => {
            //     console.log("reiniciando...");
            //     // setIsLive(false);
            //   }}
            //   className="fluid"
            // />
          )}
        </div>
        {/* {deviceOrientation === "portrait" && (
          <ObituarioMenu id={atob(match.params.codigo)}></ObituarioMenu>
        )} */}
      </IonContent>
    </IonPage>
  );
};

export default Home;
