import { useState, useEffect } from "react";

const useDeviceOrientation = () => {
  const [deviceOrientation, setDeviceOrientation] = useState<string>("");
  useEffect(() => {
    if (window.innerWidth > window.innerHeight)
      setDeviceOrientation("landscape");
    else setDeviceOrientation("portrait");

    window.addEventListener("orientationchange", e => {
      if (window.matchMedia("(orientation: portrait)").matches)
        setDeviceOrientation("landscape");

      if (window.matchMedia("(orientation: landscape)").matches)
        setDeviceOrientation("portrait");
    });
  }, []);

  return deviceOrientation;
};

export default useDeviceOrientation;
