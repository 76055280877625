import {
  IonHeader,
  IonToolbar,
  IonCard,
  IonItem,
  IonAvatar,
  IonCardContent,
  IonLabel,
  IonCardHeader,
  IonText,
  IonButtons,
  IonButton,
  IonIcon,
  IonImg,
  IonGrid,
  IonRow,
  IonCol
} from "@ionic/react";
import React, { Fragment, useState, useEffect } from "react";
import { logoFacebook, logoWhatsapp, add } from "ionicons/icons";
import CondolenciaModal from "./condolenciaModal";
import moment from "moment";
require("moment/locale/es.js");
const ObituarioMenu: React.FC<{
  menuMode?: boolean;
  id: any;
}> = ({ id, menuMode }) => {
  const [showModalCondolencia, setShowModalCondolencia] = useState(false);
  const [obituario, setObituario] = useState<any>({});
  const [condolencias, setCondolencias] = useState<Array<any>>([]);
  useEffect(() => {
    fetch(
      `https://www.live.orglaesperanza.com/php/obituario.php?obituarioId=` + id
    )
      .then((res: any) => res.json())
      .then(res => {
        console.log(res);
        setObituario(res);
      });

    fetch(
      `https://www.live.orglaesperanza.com/php/condolencias.php?obituarioId=` +
        id
    )
      .then((res: any) => res.json())
      .then(res => {
        console.log(res);
        setCondolencias(res);
      });
  }, [id]);

  useEffect(() => {
    fetch(
      `https://www.live.orglaesperanza.com/php/condolencias.php?obituarioId=` +
        id
    )
      .then((res: any) => res.json())
      .then(res => {
        console.log(res);
        setCondolencias(res);
      });
  }, [id, showModalCondolencia]);

  return (
    <Fragment>
      <CondolenciaModal
        showModal={showModalCondolencia}
        setShowModal={setShowModalCondolencia}
      ></CondolenciaModal>
      <div style={{ overflowY: "auto" }}>
        <IonHeader className="ion-no-padding" style={{ background: "#ebefbf" }}>
          <div className="ion-padding">
            <div
              style={{
                border: " 1px solid #B6B98C",
                padding: "0px 4px",
                background: "#ebefbf"
              }}
              className="ion-padding"
            >
              <IonGrid>
                <IonRow className="ion-justify-content-center ion-align-items-center">
                  <IonCol size="4">
                    <IonAvatar
                      className="square"
                      style={{
                        position: "relative",
                        width: "100%",
                        content: "",
                        display: "block",
                        paddingBottom: "100%"
                      }}
                    >
                      <IonImg
                        style={{
                          objectFit: "cover",
                          position: "absolute",
                          width: "100%",
                          height: "100%",
                          borderRadius: "100%",
                          border: "8px solid #f9f9f9a1"
                        }}
                        className="content"
                        src={
                          "https://www.organizacionlaesperanza.com/imagenes/vdo_obituarios/" +
                          obituario.foto
                        }
                        onError={() => {
                          console.log("imagen no existe");
                          setObituario({
                            ...obituario,
                            foto: "obit_824ddc548bab15c0e2d07068c1c09852.png"
                          });
                        }}
                        alt=""
                      />
                    </IonAvatar>
                  </IonCol>
                  <IonCol>
                    <div className="ion-padding">
                      <IonText className="ion-text-center">
                        <h1 className="ion-text-uppercase PTsans">
                          <b>{obituario.nombre}</b>
                        </h1>
                      </IonText>
                    </div>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonText>
                    <p className="ion-text-capitalize">
                      <b>Velación:</b> {obituario.velacion_fecha}{" "}
                      {obituario.velacion_hora} <br />
                      {obituario.velacion_lugar}
                    </p>
                  </IonText>
                  <IonText>
                    <p className="ion-text-capitalize">
                      <b>Exequias:</b> {obituario.exequias_fecha}{" "}
                      {obituario.exequias_hora} <br />
                      {obituario.exequias_lugar}
                    </p>
                  </IonText>
                  <IonText>
                    <p className="ion-text-capitalize">
                      <b>Destino final:</b> {obituario.final_fecha}{" "}
                      {obituario.final_hora} <br />
                      {obituario.final_lugar}
                    </p>
                  </IonText>
                </IonRow>
              </IonGrid>
            </div>
          </div>
        </IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonButton onClick={() => setShowModalCondolencia(true)}>
              <IonIcon icon={add}></IonIcon> Firmar
            </IonButton>
          </IonButtons>
          <IonButtons slot="end">
            <IonButton
              href={`https://www.facebook.com/sharer.php?u=https://www.organizacionlaesperanza.com/vdo_obituarios/virtual/%23/${btoa(
                id
              )}&quote=%0APin%20de%20conexi%C3%B3n:%20`}
            >
              <IonIcon icon={logoFacebook}></IonIcon>
            </IonButton>
            <IonButton
              href={`whatsapp://send?text=https://www.organizacionlaesperanza.com/vdo_obituarios/virtual/%23/${btoa(
                id
              )}%20%0APin%20de%20conexi%C3%B3n:%20`}
            >
              <IonIcon icon={logoWhatsapp}></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>

        {condolencias.map((condolencia: any, key: number) => (
          <IonCard key={key} color="light">
            <IonCardHeader className="ion-no-padding ion-padding-horizontal">
              <IonItem className="ion-no-padding" lines="none">
                <IonAvatar slot="start">
                  <img
                    src="https://www.organizacionlaesperanza.com/imagenes/vdo_obituarios/obit_824ddc548bab15c0e2d07068c1c09852.png"
                    alt={condolencia.nombre}
                  />
                </IonAvatar>
                <IonLabel>
                  <h3 className="ion-no-margin">{condolencia.nombre}</h3>
                  <small>
                    {moment(condolencia.fecha)
                      .locale("es")
                      .fromNow()}
                  </small>
                </IonLabel>
              </IonItem>
            </IonCardHeader>
            <IonCardContent className="ion-no-padding ion-padding-horizontal ion-padding-bottom">
              <p>{condolencia.mensaje}</p>
            </IonCardContent>
          </IonCard>
        ))}
      </div>
    </Fragment>
  );
};

export default ObituarioMenu;
